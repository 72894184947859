import { useEffect, useState } from "react"
import Banner from "../component/Banner"
import Footer from "../component/nav/Footer"
import NavMainpage from "../component/nav/Nav_mainpage"
import Title from "../component/nav/Title"
import ProductFilter from "../component/ProductFilter"
import Productlist from "../component/Productlist"

const Product = () => {
  const [data, getData] = useState([])
  const [brand, getBrand] = useState([])

  useEffect(()=>{
    async function fetchData() {
      const res = await fetch("/api/product/get_data.php")
      const response = await res.json()
      getData(response)
    }
    async function fetchBrand() {
      const res = await fetch("/api/product/get_brand_data.php")
      const response = await res.json()
      getBrand(response)
    }
    fetchData()
    fetchBrand()
  }, [])

  return (
    <>
      <Title titleF="Product" titleB="YP Enterprise Solution"/>
      <NavMainpage/>
      <Banner title="สินค้า | Products"/>
      <div className="main-tab" style={{backgroundColor: "#f0f0f0"}}>
        <div className="main-intab" style={{borderRadius: "10px", overflow: "hidden", border: "0.3px solid #bebebe", backgroundColor: "#fff"}}>
          {brand.map(val=>{
            const filter = data.filter(product=>{
              return JSON.parse(product.categories).brand === val.brand
            })
            return (<Productlist data={filter} brand={{brand: val.brand, cate1: JSON.parse(val.cate1), cate2: JSON.parse(val.cate2)}}/>)
          })}
        </div>
      </div>
      <Footer/>
    </>
  )
}
export default Product