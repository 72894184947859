import React, { useState } from 'react'
import Banner from '../component/Banner'
import Footer from '../component/nav/Footer'
import NavMainpage from '../component/nav/Nav_mainpage'
import Title from '../component/nav/Title'
import styles from './Document.module.css'

export default function Document_Check() {
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [number, setNumber] = useState("")

  const [phoneWrong, setPhoneWrong] = useState(false)
  const [nameWrong, setNameWrong] = useState(false)
  const [codeWrong, setCodeWrong] = useState(false)
  const [numberWrong, setNumberWrong] = useState(false)

  const [phonereason, setPReason] = useState("");
  const [namereason, setNReason] = useState("");
  const [codereason, setCReason] = useState("");
  const [numberreason, setNumberReason] = useState("")

  const teste = {
    name: 'Auttakorn',
    phone: '0629855304',
    issuer: "Earth",
    date: "12/21/20",
    details: "this is work",
    doc_code: "aaaaaa09112562"
  }
  const [data, setData] = useState(null)

  function onSubmitt(e) {
    e.preventDefault()
    async function fetchData() {
      const tosend = new FormData();
      tosend.append("name", name)
      tosend.append("phone", phone)
      tosend.append("code", code)
      tosend.append("number", number)
      tosend.append("date", getDate())
      const res = await fetch("/api/document/checker.php", {
        method: "POST",
        body: tosend
      })
      const response = await res.json()
      if (Number(response.status) === 400) {
        if (Number(response.codeStatus) === 400) {
          setCodeWrong(true)
          setCReason(response.codeError)
          return
        } else {
          setCodeWrong(false)
          setCReason("")
        }
        if (Number(response.numberStatus) === 400) {
          setCodeWrong(true)
          setCReason(response.numberError)
          return
        } else {
          setCodeWrong(false)
          setCReason("")
        }
        setData(null)
      } else {
        setData(response.data)
        try {clearInterval(id)} catch (error) {}
        let id = setInterval(()=>{
          window.scrollBy({top: 800, left: 0, behavior: "smooth"})
          clearInterval(id)
        }, 1000)
        
      }
    }
    if (checkData()) {
      fetchData()
    }
  }

  function getDate() {
    const date = new Date()
    let datenow = `${date.getHours()<10 ? "0"+date.getHours() : date.getHours()}`+":"+`${date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes()}`+" - "+date.getDay()+"/"+(date.getMonth()+1)+"/"+(date.getFullYear()+543)
    return datenow
  }

  function checkData() {
    let p = true, n = true, c = true, num=true
    if (phone.length<9 || phone.length>10) {
      setPhoneWrong(true)
      setPReason("*กรุณากรอกหมายเลขโทรศัพท์ไม่ถูกต้อง")
      p = false
    } else {
      setPhoneWrong(false)
      setPReason("")
    }
    if (name==="") {
      setNameWrong(true)
      setNReason("*กรุณากรอกชื่อผู้ขอตรวจสอบหรือหน่วยงาน")
      n = false
    } else {
      setNameWrong(false)
      setNReason("")
    }
    if (code==="") {
      setCodeWrong(true)
      setCReason("*กรุณากรอกชื่อผู้ขอตรวจสอบหรือหน่วยงาน")
      c = false
    } else {
      setCodeWrong(false)
      setCReason("")
    }
    if (number==="") {
      setNumberWrong(true)
      setNumberReason("*กรุณากรอกหมายเลขเอกสาร")
      num = false
    } else {
      setNumberWrong(false)
      setNumberReason("")
    }
    return p && n && c && num;
  }

  return (
    <>
      <Title titleF="Document Checking" titleB="YP Enterprise Solution"/>
      <NavMainpage/>
      <Banner title="ตรวจสอบเอกสาร | Document Checker"/>
      <div className='main-tab' style={{backgroundColor: "white"}}>
        <div className='main-intab'>
          <h3 style={{width: "100%", textAlign: "center"}}>ระบบตรวจสอบเอกสาร | Document Checking</h3>
          
          <div style={{width: "100%", display: "flex", flexDirection: "column", alignItems: "center", padding: "20px 0", margin: "20px 0"}}>
            <ul style={{width: "80%", color: "#414141", marginBottom: "20px"}}>
              <li>กรุณาตรวจสอบข้อมูลที่กรอกให้ครบถ้วนก่อนทำการค้นหา</li>
              <li>หากเกิดปัญหาให้ทำการติดต่อทางพนักงานของบริษัท YP Enterprise Solution</li>
              <li>กรุณางดการกดค้นหาติดต่อกัน เพราะอาจทำให้เบอร์โทรศัพท์ของท่านถูกบล็อคจากทางเว็บไซต์</li>
            </ul>
            <div style={{width: "80%", margin: "10px 0"}}>
              <h4 style={{color: `${nameWrong ? "#e43324" : "#000"}`}}>ชื่อผู้ขอตรวจสอบหรือหน่วยงาน : <b style={{fontSize: "14px"}}>{namereason}</b></h4>
              <input className={nameWrong ? styles.inputwrong : ""} onChange={event=>setName(event.target.value)} style={{padding: "10px", fontSize: "1rem", borderRadius: "5px", borderWidth: "0.1px", width: "100%"}} placeholder='กรอกชื่อผู้ขอตรวจสอบหรือหน่วยงาน...'/>
            </div>
            <div style={{width: "80%", margin: "10px 0"}}>
              <h4 style={{color: `${phoneWrong ? "#e43324" : "#000"}`}}>หมายเลขโทรศัพท์ : <b style={{fontSize: "14px"}}>{phonereason}</b></h4>
              <input className={phoneWrong ? styles.inputwrong : ""} onChange={event=>setPhone(event.target.value)} type="number" style={{padding: "10px", fontSize: "1rem", borderRadius: "5px", borderWidth: "0.1px", width: "100%"}} placeholder='กรอกเบอร์โทรศัพท์...'/>
            </div>
            <div style={{width: "80%", margin: "10px 0"}}>
              <h4 style={{color: `${codeWrong ? "#e43324" : "#000"}`}}>รหัสยืนยันบนเอกสาร : <b style={{fontSize: "14px"}}>{codereason}</b></h4>
              <input className={codeWrong ? styles.inputwrong : ""} onChange={event=>setCode(event.target.value)} style={{padding: "10px", fontSize: "1rem", borderRadius: "5px", borderWidth: "0.1px", width: "100%"}} placeholder='กรอกรหัสยืนยันบนเอกสาร...'/>
            </div>
            <div style={{width: "80%", margin: "10px 0"}}>
              <h4 style={{color: `${numberWrong ? "#e43324" : "#000"}`}}>เลขที่เอกสาร : <b style={{fontSize: "14px"}}>{numberreason}</b></h4>
              <input className={numberWrong ? styles.inputwrong : ""} onChange={event=>setNumber(event.target.value)} style={{padding: "10px", fontSize: "1rem", borderRadius: "5px", borderWidth: "0.1px", width: "100%"}} placeholder='กรอกหมายเลขเอกสาร...'/>
            </div>
            <button onClick={onSubmitt} className='btn-submit'>ค้นหา</button>
          </div>
        </div>
      </div>
      {data===null ? <></> : <div className='main-tab' style={{backgroundColor: "#f0f0f0"}}>
        <div className='main-intab'>
          <div className={styles.line}></div>
          <h2 style={{width: "100%", textAlign: "center"}}>พบข้อมูลในระบบ</h2>
          <h2 style={{width: "100%", textAlign: "center", color: "#393939"}}>รหัสเอกสาร : {data.doc_code}</h2>
          <div className={styles.line}></div>
          <div className={styles.detail}>
            <h4 style={{borderTop: "#c5c5c567 solid 0.5px"}}>ออกให้ : {data.name}</h4>
            <h4 style={{borderTop: "#c5c5c567 solid 0.5px"}}>เลขที่เอกสาร : {data.number}</h4>
            <h4>ชื่อผู้ออกเอกสาร : {data.issuer}</h4>
            <h4>วันที่ออกเอกสาร : {data.date.split("-")[2]+"/"+data.date.split("-")[1]+"/"+data.date.split("-")[0]}</h4>
            <h4>รหัสเอกสาร : {data.doc_code}</h4>
            <h4>สถานะเอกสาร : <b style={{color: `${data.status==="ปกติ" ? "#3DFF3D" : (data.status==="ยกเลิก" ? "#e43324" : "#FFF341")}`}}>{data.status}</b></h4>
            <h4 style={{width: "100%", margin: "0"}}>รายละเอียด : {data.details}</h4>
          </div>
          <div className={styles.line}></div>
          <iframe
            src={`/files/document/${data.file}`}
            width="100%"
            height="700px"
          ></iframe>
        </div>
      </div>}
      <Footer/>
    </>
  )
}
