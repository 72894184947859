import { Link } from "react-router-dom"
import LogoText from "../../image/YP_ENTERPRISE_WITH_TEXT_LOGO.png"
import { AiOutlineDown } from "react-icons/ai";
import { useState } from "react"
import "./Nav_mainpage.css"

const NavMainpage = () => {
    const [iconActivate, setIconActivate] = useState(false)
    const iconOnClick = (e) => {
        e.preventDefault()
        setIconActivate(!iconActivate)
    }
    return (
        <>
            <div className="Nav-bar">
                <Link to="/" style={{height: "100%", display: "flex", alignItems: "center"}}><img src={LogoText} alt="" style={{height: "85%"}}/></Link>
                <ul className="font-wht font-bold height100 ul-Nav-main" style={{listStyle:"none", flexDirection:"row"}}>
                    <Link className="list-Nav-Link height100 alicen" to="/" style={{padding:"0 15px"}}>หน้าแรก</Link>
                    <Link className="list-Nav-Link height100 alicen" to="/about_us" style={{padding:"0 15px"}}>เกี่ยวกับเรา</Link>
                    <Link className="list-Nav-Link height100 alicen" to="/product" style={{padding:"0 15px"}}>สินค้า</Link>
                    <li className="list-Nav-Link height100 alicen" style={{padding:"0 15px"}}>เพิ่มเติม&nbsp;<AiOutlineDown />
                        <ul>
                            <Link className="list-Nav-Link height100 alicen" to="/dealer_registration"><li>สมัครตัวแทน</li></Link>
                            <Link className="list-Nav-Link height100 alicen" to="/document_check"><li>ตรวจเช็คเอกสาร</li></Link>
                            <Link className="list-Nav-Link height100 alicen" to="/warranty_check"><li>ตรวจเช็คประกันสินค้า</li></Link>
                        </ul>
                    </li>
                </ul>
                <div className={`icon-Nav ${iconActivate ? "active" : "inactive"}`} onClick={event => iconOnClick(event)} ></div>
                <ul className={`ul-ham ${iconActivate ? "active" : "inactive"}`}>
                    <Link to="/">หน้าแรก | Home</Link>
                    <Link to="/about_us">เกี่ยวกับเรา | About Us</Link>
                    <Link to="/product">สินค้า | Products</Link>
                    <Link to="/dealer_registration">สมัครตัวแทน | Dealer Registration</Link>
                    <Link to="/document_check">ตรวจเช็คเอกสาร | Document Check</Link>
                    <Link to="/warranty_check"><li>ตรวจเช็คประกันสินค้า | Warranty Check</li></Link>
                </ul>
            </div>
        </>
    )
}
export default NavMainpage