import React, { useEffect, useState } from 'react'
import styles from "./ProductTable.module.css"

export default function ProductTable(prop) {
  const [data, setData] = useState([])

  useEffect(()=>{
    if (prop.data!==[]) {
        setData(prop.data)
    }
  }, [])

  return (
    <div className='lengthCont'>
        <table border={1} className={styles.producttable}>
            <thead>
                <tr>
                    <th colSpan={2}>คุณสมบัติ | Properties</th>
                </tr>
            </thead>
            <tbody>
                {data.map(val=>{
                    return (<>
                        <tr>
                            <th style={{textAlign: "start"}} colSpan={2}>{val[0]}</th>
                        </tr>
                        {typeof val[1]==="object" ? val[1].map(item=>{
                            return (<tr>
                                <td>{item[0]}</td>
                                <td>{item[1]}</td>
                            </tr>)
                        }) : <></>}
                    </>)
                })}
            </tbody>
        </table>
        <h5 style={{color: "#e43324", textDecoration: "underline", marginTop: "15px"}}>หมายเหตุ : ถ้าหากการแปลหรือข้อมูลผิดพลาดต้องขออภัยมา ณ ที่นี่ กรุณาตรวจสอบข้อมูลก่อนนำไปใช้</h5>
    </div>
  )
}
