import React, { useState } from 'react'
import Banner from '../component/Banner'
import Footer from '../component/nav/Footer'
import NavMainpage from '../component/nav/Nav_mainpage'
import Title from '../component/nav/Title'
import styles from './Warranty.module.css'

export default function Warranty_Check() {
  const [code, setCode] = useState("")

  const [codeError, setCodeError] = useState(false)

  const [codeReason, setCodeReason] = useState("")

  const test = ["","IPC", "SN-IPR5122BZBN-M", "S12311220802040001", "SUNELL", "normal", "activated", "8/11/2565", "8/11/2567"]
  const [data, setData] = useState(null)

  const [useStatus, setUseStatus] = useState("อยู่ระหว่างการรับประกัน")

  const [engLag, setEnglag] = useState(false)

  function onSubmitt() {
    if (!check()) {
        return
    }

    async function searchData() {
        const codetosend = new FormData()
        codetosend.append("code", code)
        const res = await fetch("/api/warranty/checknew.php", {
            method: "POST",
            body: codetosend
        })
        const response = await res.json()
        if (response['status'] === 400) {
            setCodeError(true)
            setCodeReason("ไม่พบรหัสสินค้าดังกล่างในฐานข้อมูล กรุณาติดต่อทางพนักงานบริษัท")
            setData(null)
        } else {
            setCodeError(false)
            setCodeReason("")
            setData(response.data)
            checkUseStatus(response.data)
            try {clearInterval(id)} catch (error) {}
            let id = setInterval(()=>{
                window.scrollBy({top: 800, left: 0, behavior: "smooth"})
                clearInterval(id)
            }, 1000)
        }
    }

    searchData()
  }

  function check() {
    if (code==="") {
        setCodeError(true)
        setCodeReason("กรุณากรอกรหัสสินค้า")
        return false
    } else {
        setCodeError(false)
        setCodeReason("")
        return true
    }
  }

  function checkUseStatus(status) {
    if (status[5]==="closed") {
        setUseStatus("หมดประกัน")
    } else if (status.type!=="life-time") {
        const date = new Date()
        const spliting_date = status[8].split("/")
        let year=spliting_date[2], month=spliting_date[1], day=spliting_date[0]
        if (Number(year)===date.getFullYear()) {
            if (Number(month)===date.getMonth()+1) {
                if (Number(day)<date.getDate()) {
                    setUseStatus("หมดประกัน")
                }
            } else if (Number(month)<date.getMonth()+1) {
                setUseStatus("หมดประกัน")
            }
        } else if (Number(year)<date.getFullYear()) {
            setUseStatus("หมดประกัน")
        }
    }
  }
  return (
    <>
        <Title titleF="Warranty Checking" titleB="YP Enterprise Solution"/>
        <NavMainpage/>
        <Banner title="ตรวจสอบการรับประกันของสินค้า | Warranty Checker"/>
        <div className='main-tab' style={{backgroundColor: "white"}}>
            <div className='main-intab'>
                <h3 style={{width: "100%", textAlign: "center"}}>ระบบตรวจสอบการรับประกันสินค้า | Warranty Checking</h3>
                <div style={{display: "flex", width: "100%", flexDirection: "column", alignItems: "center", padding: "20px 0", margin: "20px 0"}}>
                    <ul style={{width: "80%", color: "#414141", marginBottom: "20px"}}>
                        <li>{engLag ? "Please check your information entered completely before searching." : "กรุณาตรวจสอบข้อมูลที่กรอกให้ครบถ้วนก่อนทำการค้นหา"}</li>
                        <li>{engLag ? "If there is a problem, please contact the staff of the YP Enterprise Solution company." : "หากเกิดปัญหาให้ทำการติดต่อทางพนักงานของบริษัท YP Enterprise Solution"}</li>
                        <button onClick={()=>setEnglag(!engLag)} style={{marginTop: "10px"}} className={styles.swtLang}>{engLag ? "เปลี่ยนภาษา" : "Switch Language"}</button>
                    </ul>
                    <div style={{width: "80%", margin: "10px 0"}}>
                        <h4 style={{color: `${codeError ? "#e43324" : "#000"}`}}>Serial Number : <b style={{fontSize: "14px"}}>{codeReason}</b></h4>
                        <input className={codeError ? styles.inputwrong : ""} onChange={event=>setCode(event.target.value)} style={{padding: "10px", fontSize: "1rem", borderRadius: "5px", borderWidth: "0.1px", width: "100%"}} placeholder='กรอกรหัสสินค้า...'/>
                    </div>
                    <button onClick={onSubmitt} className='btn-submit'>ค้นหา | Search</button>
                </div>
            </div>
        </div>
        {data!==null ? <div className='main-tab' style={{backgroundColor: "#f0f0f0"}}>
        <div className='main-intab'>
          <div className={styles.line}></div>
          <h2 style={{width: "100%", textAlign: "center"}}>{engLag ? "Information Found" : "พบข้อมูลในระบบ"}</h2>
          <h2 style={{width: "100%", textAlign: "center", color: "#393939"}}>{engLag ? "Serial Number : " : "รหัสสินค้า : "}{data[3]}</h2>
          <div className={styles.line}></div>
          <div className={styles.detail}>
            <h4 style={{borderTop: "#c5c5c567 solid 0.5px"}}>{engLag ? "Product Name : " : "ชื่อสินค้า : "}{data[2]}</h4>
            <h4 style={{borderTop: "#c5c5c567 solid 0.5px"}}>{engLag ? "Serial Number : " : "S/N ซีเรียลนัมเบอร์ : "}{data[3]}</h4>
            <h4>{engLag ? "Start Date : " : "เริ่มในวันที่ : "}{data[7]}</h4>
            <h4>{engLag ? "End Date : " : "สิ้นสุดการรับประกันในวันที่ : "}{data[5]==="life-time" ? "-" : (data[8])}</h4>
            <h4>{engLag ? "Warranty Type : " : "ประเภทของประกัน : "}{data[5]==="life-time" ? data[5] : (data[5]==="normal" ? `${engLag ? "Normal" : "ปกติ"}` : `${engLag ? "In every case" : "ทุกกรณี"}`)}</h4>
            <h4>{engLag ? "Status : " : "สถานะปัจจุบัน : "}<b style={{color: `${useStatus==="อยู่ระหว่างการรับประกัน" ? "#3DFF3D" : "#e43324"}`}}>{engLag ? `${useState==="อยู่ระหว่างการรับประกัน" ? "Insured" : "Out of warranty"}` : useStatus}</b></h4>
            <h4 style={{width: "100%", margin: "0"}}>{engLag ? "Start Date : " : "รายละเอียด : "}{data[9]===""|| data[9]==="-" ? "-" : data.detail}</h4>
          </div>
          <div className={styles.line}></div>
        </div>
        </div> : <></>}
        <Footer/>
    </>
  )
}
