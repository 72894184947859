import React, { useEffect, useState } from 'react'
import "./Slide.css"

export default function Slide(prop) {
  const [image, getImage] = useState([])
  const [count, setCount] = useState([])
  const [onShowing, setOnShowing] = useState(0)
  
  useEffect(()=>{
    getImage(prop.image)
    try {
      let createCount = []
      for (let i=0; i<prop.image.length; i++) {
        createCount.push(i)
      }
      setCount(createCount)
    } catch (err) {}
    
  }, [prop.image])

  var timer = 0

  function start() {
    timer = setInterval(()=>{
      if (onShowing===count.length-1) {
        setOnShowing(0)
      } else {
        setOnShowing(onShowing+1)
      }
    }, 15000)
  }

  start()

  return (
    <>
      <div className="slideBody">
        {/* <div className="arrow" onClick={backImage} style={{left: "1%"}}>&#10094;</div>
        <div className="arrow" onClick={nextImage} style={{right: "1%"}}>&#10095;</div>
        
        <div style={{position: "absolute", bottom: "1%", zIndex: 7}}>
          {count.map(val =>{
            return (
              <>
                <span onClick={event=>toThePage(event, val)} className={`dot ${val===onShowing ? "active" : ""}`}></span>
              </>
            )
          })}
        </div>*/}
        {count.map(val=>{
          return (<>
            <img className='imgSlide' src={`https://ypenterprisesolution.com/images/${prop.type}/${image[val]}`} alt="" style={{height: "100%", display: `${val===onShowing ? "block" : "none"}`}}/>
          </>)
        })}
      </div>
    </>
  )
}