import { useState } from "react"
import Banner from "../component/Banner"
import Form from "../component/Form"
import Footer from "../component/nav/Footer"
import NavMainpage from "../component/nav/Nav_mainpage"
import Title from "../component/nav/Title"


const DealRegis = () => {
  const [lang, setLang] = useState(false)
  return (
    <>
      <Title titleF="Dealer Registration" titleB="YP Enterprise Solution"/>
      <NavMainpage/>
      <Banner title="สมัครตัวแทนจำหน่าย | Dealer Registration"/>
      <div className="main-tab" style={{backgroundColor: "white"}}>
        <div className="main-intab">
          <h3 style={{width: "100%", textAlign: "center"}}>แบบฟอร์มสำหรับสมัครเป็นดีลเลอร์ | Form for Dealer Registration</h3>
          <h4 style={{width: "100%", textAlign: "center", color: "#e43324", textDecoration: "underline"}}>ข้อกำหนดและขั้นตอนในการดำเนินการ | Requirements and procedures</h4>
          <h5 style={{width: "100%", textAlign: "center", color: "#e43324", textDecoration: "underline"}}>กรุณาอ่านรายละเอียดเบื้องต้นก่อนกรอกแบบฟอร์ม<br/>Please read carefully before filling the form.</h5>
          <div className='pro-cont-win' style={{marginTop: "20px"}}>
            <div className='pro-cont-groupswitch'>
                <h4 onClick={()=>setLang(false)} style={{borderRight: "#b8b8b8 solid 0.2px", padding: "8px 24px"}} className={`pro-cont-switch${lang ? "" : "-select"}`}>ไทย</h4>
                <h4 onClick={()=>setLang(true)} className={`pro-cont-switch${lang ? "-select" : ""}`}>English</h4>
            </div>
            <div className='pro-cont-show'>
              {lang ? <>
                <h4 style={{margin: "0 0 10px 0"}}>Requirements</h4>
                <p style={{paddingLeft: "25px"}}>1.) Filling out the form must be completed in its entirety.</p>
                <p style={{paddingLeft: "25px"}}>2.) Please fill out the form truthfully.</p>
                <p style={{paddingLeft: "25px"}}>3.) Please do not submit the same form multiple times.</p>
                <h4 style={{margin: "10px 0"}}>Steps to do</h4>
                <p style={{paddingLeft: "25px"}}>1.) After completing all the forms The company will verify your information. This process may take approximately 1-5 days.</p>
                <p style={{paddingLeft: "25px"}}>2.) If the company considers and checks your information completed. The company will call the phone number that you have entered in the form.</p>
                <p style={{paddingLeft: "25px"}}>3.) The company will tell you more details about the next steps.</p>
                <h4 style={{margin: "10px 0", color: "#e43324"}}>In the event of an error</h4>
                <p style={{paddingLeft: "25px", color: "#e43324"}}>1.) You can inform to telephone 020-772534. You can call during the period (Mon.-Fri. : 09.00 - 16.30)</p>
                <p style={{paddingLeft: "25px", color: "#e43324"}}>2.) If your information is incorrect or not qualified. The company will notify phone number or your email address that you filled in the form</p>
              </> : <>
                <h4 style={{margin: "0 0 10px 0"}}>ข้อกำหนด</h4>
                <p style={{paddingLeft: "25px"}}>1.) การกรอกแบบฟอร์มต้องกรอกให้ครบทั้งหมดฟอร์ม</p>
                <p style={{paddingLeft: "25px"}}>2.) กรุณากรอกแบบฟอร์มตามความเป็นจริง</p>
                <p style={{paddingLeft: "25px"}}>3.) กรุณาไม่ส่งแบบฟอร์มซ้ำกันหลาย ๆ ครั้ง</p>
                <h4 style={{margin: "10px 0"}}>ขั้นตอนในการดำเนินการ</h4>
                <p style={{paddingLeft: "25px"}}>1.) หลังจากกรอกแบบฟอร์มทั้งหมดเสร็จสิ้น ทางบริษัทจะทำการตรวจสอบข้อมูลของท่าน ในกระบวนการนี้อาจจะใช้เวลาโดยประมาณ 1-5 วัน</p>
                <p style={{paddingLeft: "25px"}}>2.) ถ้าหากทางบริษัทพิจารณาและตรวจสอบข้อมูลของท่านเสร็จสิ้น ทางบริษัทจะทำการโทรศัพท์ไปยังหมายเลขโทรศัพท์ที่ท่านได้กรอกไว้ในแบบฟอร์ม</p>
                <p style={{paddingLeft: "25px"}}>3.) ทางบริษัทจะทำการบอกรายละเอียดเพิ่มเติมเกี่ยวกับขั้นตอนถัดไปให้แก่ท่าน</p>
                <h4 style={{margin: "10px 0", color: "#e43324"}}>กรณีที่เกิดข้อผิดพลาด</h4>
                <p style={{paddingLeft: "25px", color: "#e43324"}}>1.) ท่านสามารถแจ้งมายัง โทรศัพท์ 020-772534 โดยท่านสามารถโทรศัพท์แจ้งได้ในช่วงเวลา (จ.-ศ. : 09.00 - 16.30)</p>
                <p style={{paddingLeft: "25px", color: "#e43324"}}>2.) หากข้อมูลของท่านไม่ถูกต้องหรือไม่ผ่านคุณสมบัติ ทางบริษัทจะทำการแจ้งไปยัง หมายเลขโทรศัพท์ หรือ Email ของท่าน ที่กรอกในแบบฟอร์ม</p>
              </>}
            </div>
          </div>
        </div>
      </div>
      <div className="main-tab" style={{backgroundColor: "#f3f3f3"}}>
        <div className="main-intab">
          <Form/>
        </div>
      </div>
      <Footer/>
    </>
  )
}
export default DealRegis