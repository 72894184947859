import './Banner.css'
import logo from "../image/YP_ENTERPRISE_LOGO.png"

const Banner = (prop) => {
  return (
    <>
        <div className='header-banner'>
            <img src={logo} alt=""/>
            <div className='banner-title2' style={{color: "#fff"}}>{prop.title}</div>
        </div>
    </>
  )
}
export default Banner