const Footer = () => {
  return (
    <>
        <div className="width100 font-wht" style={{textAlign: "center", padding: "30px 10px"}}>
            <h2 style={{color: "white"}}>Copyright © 2022 YP Enterprise Solutions</h2>
            <p style={{color: "white"}}>เลขที่ 10/6 หมู่ที่ 23 ตำบลรอบเวียง อำเภอเมือง จังหวัดเชียงราย 57000
                <br/>โทรศัพท์ 020-772534 , ช่างฝ่ายเทคนิค 061-274-0642
                <br/>No.10/6, Village no.23, Subdistrict RobWiang, District Meung, Chiang Rai Province, 57000
                <br/>Hotline 020-772534, Technician 0612740642, WebDev : Auttakorn Camsoi</p>
        </div>
    </>
  )
}
export default Footer