import { Helmet } from 'react-helmet'

const Title = (prop) => {
  return (
    <>
        <Helmet>
            <title>{prop.titleF} | {prop.titleB}</title>
        </Helmet>
    </>
  )
}
export default Title