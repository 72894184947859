import NavMainpage from "../component/nav/Nav_mainpage"
import "./Home.css"
import logo from "../image/YP_ENTERPRISE_LOGO.png"
import Title from "../component/nav/Title"
import Footer from "../component/nav/Footer"
import TextPic from "../component/TextPic"
import Text from "../component/Text"
import Slide from "../component/Slide"
import { useEffect, useState } from "react"

const Home = () => {
  const [data, getData] = useState([])
  const [count, setCount] = useState([])
  useEffect(()=>{
    async function fetchData() {
      // todo to Deploy : https://ypenterprisesolution.com/
      const res = await fetch("/api/home/get_data.php")
      const response = await res.json()

      getData(response)
      let createCountList = []
      for (let i=0; i<response.length; i++) {
        createCountList.push(i)
      }
      setCount(createCountList)
    }

    fetchData()
  }, [])
  return (
    <>
      <Title titleF="Home" titleB="YP Enterprise Solution"/>
      <NavMainpage/>
      <div className="banner-container">
        <img src={logo} alt=""/>
        <div className="banner-title1" style={{color: "#fff", textAlign: "center"}}>YP ENTERPRISE SOLUTIONS</div>
        <div className="banner-title2" style={{color: "#e43324", textAlign: "center"}}>ENTERPRISE SOLUTIONS YOU CAN TRUST</div>
      </div>
      {count.map(val=>{
        return (<>
          {data[val].type==="textonly" ? <Text bgcolor={`${val%2===0 ? "white" : "#f0f0f0"}`} content={JSON.parse(data[val].content)}/> : <></>}
          {data[val].type==="textpic" ? <TextPic bgcolor={`${val%2===0 ? "white" : "#f0f0f0"}`} order={val} content={JSON.parse(data[val].content)} image={JSON.parse(data[val].file)} type="about_us"/> : <></>}
          {data[val].type==="pic" ? <Slide image={JSON.parse(data[val].file)} type="about_us"/> : <></>}
        </>)
      })}
      <Footer/>
    </>
  )
}
export default Home