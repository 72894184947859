import React, { useRef, useState } from 'react'
import "./Form.css"
import uploadImg from "../image/upload-bg.jpg"
import ReCAPTCHA from "react-google-recaptcha";

export default function Form() {
  const [imgPreview, setImagePreview] = useState(null)
  const [img, setImage] = useState(null)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [birth, setBirth] = useState("")
  const [company, setCompany] = useState("")
  const [code, setCode] = useState("")
  const [address, setAdress] = useState("")
  const [empty, setEmpty] = useState([])
  const [recap, setRecap] = useState("")

  function onSubmit(e) {
    e.preventDefault()
    
    if (!checkValue()) {
        return
    }

    const time = new Date()
    const now = (time.getHours()/10<1 ? "0"+time.getHours() : time.getHours()) + ":" +(time.getMinutes()/10<1 ? "0"+time.getMinutes() : time.getMinutes()) + " - " + time.getDate() +"/"+(time.getMonth()+1)+"/"+time.getFullYear()
    const surname = img.name.split(".")[1]
    const toSend = new FormData()
    toSend.append("recaptcha", recap)
    toSend.append("name", name)
    toSend.append("email", email)
    toSend.append("phone", String(phone))
    toSend.append("birth", birth)
    toSend.append("company", company)
    toSend.append("code", String(code))
    toSend.append("address", address)
    toSend.append("pic", String(code)+"."+surname)
    toSend.append("date", now)
    upLoadImg()
    fetch("/api/dealer/add_data.php", {
        method: "POST",
        body: toSend
    })
    window.location.reload()
  }

  function onChangeRe(value) {
    setRecap(value)
  }

  function upLoadImg() {
    const uploadFile = new FormData()
    const surname = img.name.split(".")[1]
    uploadFile.append("name", String(code)+"."+surname)
    uploadFile.append("file", img)
    fetch("/api/dealer/upload_file.php", {
        method: "POST",
        body: uploadFile
    })
  }

  function onUpload(e) {
    e.preventDefault()
    setImage(e.target.files[0])
    setImagePreview(URL.createObjectURL(e.target.files[0]))
  }

  function checkValue() {
    const listEmpty = []
    if (name==="") {
        listEmpty.push("name")
    }
    if (email==="" || !email.includes("@")) {
        listEmpty.push("email")
    }
    if (phone.length<9 || phone.length>10) {
        listEmpty.push("phone")
    }
    if (birth==="") {
        listEmpty.push("birth")
    }
    if (company==="") {
        listEmpty.push("company")
    }
    if (code.length!==13) {
        listEmpty.push("code")
    }
    if (address==="") {
        listEmpty.push("address")
    }
    if (img===null) {
        listEmpty.push("img")
    }
    if (recap==="") {
        listEmpty.push("recap")
    }
    setEmpty(listEmpty)
    return listEmpty.length===0
  }

  function onEdit(e, type) {
    e.preventDefault()
    setEmpty(empty.filter(val=>{
        return type!==val
    }))
  }
  return (
    <>
        <div className='dealer-form'>
            <h2 style={{width: "100%", textAlign: "center", marginBottom: "20px"}}>แบบฟอร์ม | Forms</h2>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> ชื่อ-สกุล : </h4>
                <input className={`input-dealer ${empty.includes("name") ? "noinput" : ""}`} placeholder="Name..." onChange={e=>setName(e.target.value)} onClick={event=>onEdit(event, "name")}/>
            </div>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> อีเมล : </h4>
                <input className={`input-dealer ${empty.includes("email") ? "noinput" : ""}`} placeholder="Email..." onChange={e=>setEmail(e.target.value)} onClick={event=>onEdit(event, "email")}/>
            </div>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> หมายเลขโทรศัพท์ : </h4>
                <input type="number" className={`input-dealer ${empty.includes("phone") ? "noinput" : ""}`} placeholder="Phone..." onChange={e=>setPhone(e.target.value)} onClick={event=>onEdit(event, "phone")}/>
            </div>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> วัน/เดือน/ปีเกิด : </h4>
                <input type="date" className={`input-dealer ${empty.includes("birth") ? "noinput" : ""}`} placeholder="Date..." onChange={e=>setBirth(e.target.value)}/>
            </div>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> บริษัท : </h4>
                <input className={`input-dealer ${empty.includes("company") ? "noinput" : ""}`} placeholder="Company..." onChange={e=>setCompany(e.target.value)} onClick={event=>onEdit(event, "company")}/>
            </div>
            <div className='blockinput'>
                <h4><b style={{color: "#e43324"}}>*</b> เลขนิติบุคคล : </h4>
                <input type="number" className={`input-dealer ${empty.includes("code") ? "noinput" : ""}`} placeholder="Company Number..." onChange={e=>setCode(e.target.value)} onClick={event=>onEdit(event, "code")}/>
            </div>
            <div className='blockinput' style={{width: "100%"}}>
                <h4><b style={{color: "#e43324"}}>*</b> ที่อยู่บริษัท : </h4>
                <input className={`input-dealer ${empty.includes("address") ? "noinput" : ""}`} placeholder="Company Address..." onChange={e=>setAdress(e.target.value)} onClick={event=>onEdit(event, "address")}/>
            </div>
            
            <div style={{width: "100%", display: "flex", flexDirection: "column"}}>
                <h4><b style={{color: "#e43324"}}>*</b> อัพโหลดรูปถ่ายคู่บัตรประชาชน : </h4>
                <div className={`input-dealer ${empty.includes("img") ? "noinput" : ""}`} style={{position: "relative"}}>
                <input className={`uploadinputDeal`} type="file" onChange={onUpload}/>
                    {imgPreview===null ? <img src={uploadImg} alt=""/> : <img src={imgPreview} alt=""/>}
                </div>
            </div>

            <ReCAPTCHA onChange={onChangeRe} style={{marginTop: "20px"}} sitekey='6Lcm-7ojAAAAAMzAgioN_kEc4SW-xJeF4DW3tE15'/>
            <button onClick={onSubmit} className='btn-submit'>ส่งแบบฟอร์ม</button>
        </div>
    </>
  )
}
