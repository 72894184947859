import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styles from "./Productlist.module.css"

export default function Productlist(prop) {
  const [brand, getBrand] = useState({})
  const [data, getData] = useState([])

  useEffect(()=>{
    getBrand(prop.brand)
    try {
        const cate = prop.brand.cate1.map(val=>{
            const dataInCate1 = prop.brand.cate2.map(item=>{
                let dataInCate2 = prop.data.filter(element=>{
                    return JSON.parse(element.categories).cate1 === val && JSON.parse(element.categories).cate2 ===item
                })
                if (dataInCate2.length!==0) {
                    return [item, dataInCate2]
                }
            })
            if (dataInCate1.length!==0) {
                return [val, dataInCate1.filter(item=>{
                    return item!==undefined
                })]
            }
        })
        getData(cate)
    } catch (err) {}
    
  }, [prop.data, prop.brand])

  return (
    <>
        {data.length===0 ? <></> : <><h3 style={{backgroundColor: "black", width: "100%", padding: "0px 0px 0px 10px", color: "white"}}>{brand.brand}</h3>
            {data.map(cate1m=>{
                return <>
                    {cate1m[0]==="" ? <></> : <h4 style={{backgroundColor: "#3d3d3d", width: "100%", padding: "5px 5px 5px 25px", color: "white"}}>{cate1m[0]}</h4>}
                    {cate1m[1].map(cate2m=>{
                        return <>
                            {cate2m[0]==="" ? <></> : <h4 style={{backgroundColor: "white", width: "100%", padding: "5px 5px 5px 55px", borderBottom: "0.1px solid #c5c5c5", borderTop: "0.1px solid #c5c5c5"}}>{cate2m[0]}</h4>}
                            {cate2m[1].map((filteredData, index)=>{
                                let productFeature = JSON.parse(filteredData.feature)
                                return <>
                                    <Link to={`/product/${filteredData.code}`} className={styles.window}>
                                        <div className={(index+1/4 ===0) ? styles.inWindowRight : styles.inWindow}>
                                            <img src={`https://ypenterprisesolution.com/images/product/${filteredData.code}/${JSON.parse(filteredData.file).image[0]}`} alt=""/>
                                            <h4>{filteredData.name}</h4>
                                            <h6>{(productFeature[0][0]===undefined) ? "" : productFeature[0][0] + " / "}  {(productFeature[0][1]===undefined) ? "" : productFeature[0][1] + " / "} {(productFeature[0][2]===undefined) ? "" : productFeature[0][2] + " / "}</h6>
                                            {Number(filteredData.price)===0 ? <br/> : <h5>ราคา {filteredData.price} บาท</h5>}
                                            <h5 className={styles.morehold}>เพิ่มเติม...</h5>
                                        </div>
                                 </Link>
                                </>
                            })}
                        </>
                    })}
                </>
            })}
        </>}
    </>
  )
}
