import React, { useEffect, useState } from 'react'
import './ProductPreview.css'

export default function ProductPreview(prop) {
  const [data, getData] = useState({})
  const [imageShowing, setImageShowing] = useState(0)
  const [featureLang, setFeatureLang] = useState(false)

  useEffect(()=>{
    try {
        getData({
            file: JSON.parse(prop.data.file),
            feature: JSON.parse(prop.data.feature),
            name: prop.data.name,
            code: prop.data.code,
            price: prop.data.price,
            categories: JSON.parse(prop.data.categories)
        })
    } catch (err) {}
  }, [prop.data])

  function leftSlide(e) {
    e.preventDefault()
    if (imageShowing>0) {
        setImageShowing(imageShowing-1)
    } else {
        setImageShowing(data.file.image.length-1)
    }
  }

  function rightSlide(e) {
    e.preventDefault()
    if (imageShowing<data.file.image.length-1) {
        setImageShowing(imageShowing+1)
    } else {
        setImageShowing(0)
    }
  }

  return (
    <div className='lengthContpre'>
        <div className='columnPro'>
            <div className='product-img-slide' style={{backgroundImage: `url(https://ypenterprisesolution.com/images/product/${data.code}/${data.file===undefined ? "" : data.file.image[imageShowing]})`, backgroundSize: `${imageShowing===0 ? "cover" : "inherit"}`}}>
                {data.file===undefined ? <></> : <> {data.file.image.length===1 ? <></> : <>
                    <div className='arrowforimg' onClick={leftSlide} style={{left: "0.5%", top: "50%"}}>&#10094;</div>
                    <div className='arrowforimg' onClick={rightSlide} style={{right: "0.5%", top: "50%"}}>&#10095;</div></>}
                </>}
                
                <div className='dotgroup'>
                    {data.file===undefined ? <></> : data.file.image.length===1 ? <></> : (data.file.image).map((val, index)=>{
                        return <span className={`dotselect ${imageShowing===index ? "activedot" : ""}`}></span>
                    })}
                </div>
            </div>
        </div>
        <div className='columnPro'>
            {data.categories===undefined ? <></> : <h2 style={{color: "#000"}}>{data.categories.brand} {data.categories.cate1} : {data.name}</h2>}
            {data.price===undefined ? <></> : (Number(data.price)===0 ? <></> : <h3 style={{color: "#fff", width: "100%"}}>ราคา : {data.price} บาท</h3>)}
            <div style={{width: "100%", border: "0.2px solid #757575", borderRadius: "10px", overflow: "hidden", height: "50%"}}>
                <div style={{width: "100%", display: "flex"}}>
                    <h4 onClick={()=>{setFeatureLang(false)}} className={`${featureLang ? "switch-lang-noselect" : "switch-lang"}`}>ไทย</h4>
                    <h4 onClick={()=>{setFeatureLang(true)}} className={`${featureLang ? "switch-lang" : "switch-lang-noselect"}`} style={{width: "50%", padding: "5px 0 5px 0"}}>English</h4>
                </div>
                {featureLang ? <ul style={{width: "100%", borderTop: "0.2px solid #757575", padding: "8px", height: "100%"}} >
                    {data.feature===undefined ? <></> : data.feature[1].map((val,index)=>{
                        return (<p style={{margin: "5px 0"}}><b>{index+1}.)</b> {val}</p>)
                    })}
                </ul> :
                <ul style={{width: "100%", borderTop: "0.2px solid #757575", padding: "8px" , height: "100%"}} >
                    {data.feature===undefined ? <></> : data.feature[0].map((val, index)=>{
                        return (<p style={{margin: "5px 0"}}><b>{index+1}.)</b> {val}</p>)
                    })}
                </ul>}
            </div>
            <div style={{width: "100%"}}>
                {data.file===undefined ? <></> : data.file.file.map((val,index) => {
                    return (<a style={{width: "fit-content", height: "fit-content"}} href={`https://ypenterprisesolution.com/files/product/${data.code}/${val}`} download><h5 style={{marginBottom: `${index!==data.file.file.length-1 ? "5px" : "0"}`, marginTop: `${index===0 ? "10px" : "0"}`}} className='download-tab'>
                            Download {val} here!!
                        </h5>
                    </a>)
                })}
            </div>
        </div>
        
    </div>
  )
}
