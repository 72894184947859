import React, { useEffect, useState } from 'react'

export default function Text(prop) {
  const [content, getContent] = useState([])
  const [count, setCount] = useState([])
  useEffect(()=>{
    getContent(prop.content)
    let createCount = []
    for (let i=0; i<prop.content.length; i++) {
      createCount.push(i)
    }
    setCount(count)
  }, [prop.content])
  return (
    <>
      <div className='main-tab' style={{backgroundColor: `${prop.bgcolor}`}}>
        <div className='main-intab'>
          {count.map(val=>{
            return (<>
              <h2 style={{marginTop: `${val===0 ? "0px" : "15px"}`, marginBottom: "10px"}}>{content[val][0]}</h2>
              {typeof content[val][1] === "string" ? <>
                {content[val][1]==="" ? <></> : <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{content[val][1]}</p>}
              </> : <>
                {content[val][1].map(element=>{
                  return (
                    <p className='list'>{element}</p>
                  )
                })}
              </>}
            </>)
          })}
        </div>
      </div>
    </>
  )
}