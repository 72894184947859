import { useEffect, useState } from "react"
import Banner from "../component/Banner"
import Footer from "../component/nav/Footer"
import NavMainpage from "../component/nav/Nav_mainpage"
import Title from "../component/nav/Title"
import TextPic from "../component/TextPic"
import Text from "../component/Text"
import Slide from "../component/Slide"

const AboutUs = () => {
  const [data, getData] = useState([])
  const [count, setCount] = useState([])
  useEffect(()=>{
    async function fetchData() {
      // todo to Deploy : https://ypenterprisesolution.com/
      const res = await fetch("/api/about_us/get_data.php")
      const response = await res.json()

      getData(response)
      let createCountList = []
      for (let i=0; i<response.length; i++) {
        createCountList.push(i)
      }
      setCount(createCountList)
    }

    fetchData()
  }, [])
  return (
    <>
      <Title titleF="About Us" titleB="YP Enterprise Solution"/>
      <NavMainpage/>
      <Banner title="เกี่ยวกับเรา | About Us"/>
      {count.map(val=>{
        return (<>
          {data[val].type==="textonly" ? <Text bgcolor={`${val%2===0 ? "white" : "#f0f0f0"}`} content={JSON.parse(data[val].content)}/> : <></>}
          {data[val].type==="textpic" ? <TextPic bgcolor={`${val%2===0 ? "white" : "#f0f0f0"}`} order={val} content={JSON.parse(data[val].content)} image={JSON.parse(data[val].file)} type="about_us"/> : <></>}
          {data[val].type==="pic" ? <Slide image={JSON.parse(data[val].file)} type="about_us"/> : <></>}
        </>)
      })}
      <Footer/>
    </>
  )
}
export default AboutUs