import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Footer from '../component/nav/Footer'
import NavMainpage from '../component/nav/Nav_mainpage'
import Title from '../component/nav/Title'
import ProductDetail from '../component/ProductDetail'
import ProductPreview from '../component/ProductPreview'
import ProductTable from '../component/ProductTable'
import "./Productshow.css"

export default function Productshow() {
  const param = useParams()
  const [data, getData] = useState({})

  useEffect(()=>{
    async function fetchData() {
      const res = await fetch("/api/product/get_data.php")
      const response = await res.json()

      const filterData = response.filter(val=>{
        return val.code===param.productcode
      })
      getData(filterData[0])
    }

    fetchData()
  }, [])

  return (
    <>
      <Title titleF="Product" titleB={`${data.code===undefined ? "Not Found" : data.code}`}/>
      <NavMainpage/>
      <div className='main-page'>
        <div className='inmain-page' style={{backgroundColor: "white"}}>
          <ProductPreview data={data}/>
          
        </div>
        {data.content===undefined ? <></> : (JSON.parse(data.content)[0].length===0 && JSON.parse(data.content)[1].length===0 ? <></> : <div className='inmain-page' style={{backgroundColor: "#f3f3f3"}}>
          <ProductDetail data={data}/>
        </div>)}

        {data.table===undefined ? <></> : (JSON.parse(data.table).length===0 ? <></> : 
        <div className='inmain-page' style={{backgroundColor: `${JSON.parse(data.content)[0].length===0 && JSON.parse(data.content)[1].length===0 ? "#f3f3f3" : "#fff"}`}}>
          <ProductTable data={JSON.parse(data.table)}/>
        </div>)}

        
      </div>
      <Footer/>
    </>
  )
}
