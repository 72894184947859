import { Route, Routes } from "react-router-dom"
import Home from "./pages/Home"
import "./style/global.css"
import "./style/fontsize.css"
import Product from "./pages/Product"
import AboutUs from "./pages/About_us"
import DealRegis from "./pages/DealRegis"
import NotFound from "./pages/NotFound"
import Productshow from "./pages/Productshow"
import Document_Check from "./pages/Document_Check"
import Warranty_Check from "./pages/Warranty_Check"

const App = () => {
  return (
    <>
      <Routes>
        <Route path="" element={<Home/>}></Route>
        <Route path="product" element={<Product/>}></Route>
        <Route path="product/:productcode" element={<Productshow/>}></Route>
        <Route path="about_us" element={<AboutUs/>}></Route>
        <Route path="dealer_registration" element={<DealRegis/>}></Route>
        <Route path="document_check" element={<Document_Check/>}></Route>
        <Route path="warranty_check" element={<Warranty_Check/>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>
    </>
  )
}
export default App