import Footer from "../component/nav/Footer"
import NavMainpage from "../component/nav/Nav_mainpage"


const NotFound = () => {
  return (
    <>
        <NavMainpage/>
        <div className="width100 flex-center" style={{minHeight: "100vh", backgroundColor: "#fff", color: "black"}}>
            <h1 style={{marginTop: "80px"}}>ไม่มีหน้าเพจนี้   |   This Page's not found</h1>
        </div>
        <Footer/>
    </>
  )
}
export default NotFound