import React, { useEffect, useState } from 'react'
import "./Detail.css"

export default function ProductDetail(prop) {
  const [data, setData] = useState([[], []])
  const [lang, setLang] = useState(false)

  useEffect(()=>{
    if (prop.data!=={}) {
        setData(JSON.parse(prop.data.content))
    }
  }, [prop.data])

  return (
    <>
        <div className='lengthCont'>
            <div className='pro-cont-win'>
                <div className='pro-cont-groupswitch'>
                    <h4 onClick={()=>setLang(false)} style={{borderRight: "#b8b8b8 solid 0.2px", padding: "8px 24px"}} className={`pro-cont-switch${lang ? "" : "-select"}`}>ไทย</h4>
                    <h4 onClick={()=>setLang(true)} className={`pro-cont-switch${lang ? "-select" : ""}`}>English</h4>
                </div>
                <div className='pro-cont-show'>
                    {lang ? data[1].map(val => {
                        return (<>
                            <h3 style={{margin: "8px 0"}}>{val[0]}</h3>
                            {typeof val[1] === "object" ?
                            val[1].map((val, index)=>{
                                return (<p style={{paddingRight: "25px"}}>{index+1}.) {val}</p>)
                            }) : <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{val[1]}</p>}
                        </>)
                    })
                    
                    : data[0].map(val=>{
                        return (<>
                            <h3 style={{margin: "8px 0"}}>{val[0]}</h3>
                            {typeof val[1] === "object" ?
                            val[1].map((val, index)=>{
                                return (<p style={{paddingRight: "25px"}}>{index+1}.) {val}</p>)
                            }) : <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{val[1]}</p>}
                        </>)
                    })}
                </div>
            </div>
        </div>
    </>
  )
}
